import ReCAPTCHA from 'react-google-recaptcha';
import axios from 'axios';
import styled from 'styled-components';
import React, { FC, FormEvent, useRef, useState } from 'react';
import { Button, Container, Form, Toast } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import Header from '../../components/support/Header';
import Layout from '../../components/layout';
import SEO from '../../components/seo';

const ActionWrapper = styled.div`
  margin-top: 1rem;
`;

const index: FC = () => {
  const { t, i18n } = useTranslation();
  const { register, handleSubmit, errors, setValue, reset } = useForm();
  const [submitting, setSubmitting] = useState(false);
  const [topic, setTopic] = useState('');
  const captchaRef = useRef<ReCAPTCHA>(null);
  const [toast, setToast] = useState(false);

  const onSubmit = async (values: any) => {
    setSubmitting(true);
    await axios.post(`${process.env.GATSBY_API_URL!}/contacts`, values);
    setToast(true);
    reset({ captcha: '' });
    if (captchaRef.current) {
      captchaRef?.current.reset();
    }
    setSubmitting(false);
  };

  const onCaptchaSuccess = (token: string | null) => {
    setValue('captcha', token, true);
  };

  const onTopicChange = (e: FormEvent<HTMLSelectElement>) => {
    setTopic(e.currentTarget.value);
  };

  return (
    <Layout>
      <SEO title="Support Center" article />
      <Container>
        <Header />
        <div className="container flex-grow-1 d-flex flex-column align-items-center justify-content-center">
          <div className="w-50">
            <Form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
              <input type="hidden" name="captcha" ref={register({ required: t('error.unverify_captcha') as string })} />
              {/* email */}
              <Form.Group>
                <Form.Label>
                  <span dangerouslySetInnerHTML={{ __html: t('form.email') }}></span>
                </Form.Label>
                <input
                  className="form-control"
                  name="email"
                  ref={register({
                    required: t('error.required') as string,
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      message: t('error.invalid_email'),
                    },
                  })}
                />
                {errors.email && (
                  <div className="invalid-feedback" style={{ display: 'block' }}>
                    {errors.email.message}
                  </div>
                )}
              </Form.Group>
              {/* topic */}
              <Form.Group>
                <Form.Label>{t('form.subject')}</Form.Label>
                <select
                  className="custom-select"
                  name="topic"
                  defaultValue=""
                  onChange={onTopicChange}
                  ref={register({ required: t('error.required') as string })}>
                  <option value="">-- Topic --</option>
                  <option value="billing">{t('page.contact.billing')}</option>
                  <option value="employment">{t('page.contact.employment')}</option>
                  <option value="feature_request">{t('page.contact.feature_request')}</option>
                  <option value="press">{t('page.contact.press')}</option>
                  <option value="subscriptions">{t('page.contact.subscriptions')}</option>
                  <option value="technical">{t('page.contact.technical')}</option>
                </select>
                {errors.topic && (
                  <div className="invalid-feedback" style={{ display: 'block' }}>
                    {errors.topic.message}
                  </div>
                )}
              </Form.Group>
              {topic === 'technical' && (
                <>
                  {/* device */}
                  <Form.Group>
                    <Form.Label>{t('form.device')}</Form.Label>
                    <select
                      className="custom-select"
                      name="device"
                      defaultValue=""
                      ref={register({ required: t('error.required') as string })}>
                      <option value="">-- Device --</option>
                      <option value="desktop">{t('page.contact.desktop')}</option>
                      <option value="laptop">{t('page.contact.laptop')}</option>
                      <option value="tablet">{t('page.contact.tablet')}</option>
                      <option value="mobile">{t('page.contact.mobile')}</option>
                    </select>
                    {errors.device && (
                      <div className="invalid-feedback" style={{ display: 'block' }}>
                        {errors.device.message}
                      </div>
                    )}
                  </Form.Group>
                  {/* browser */}
                  <Form.Group>
                    <Form.Label>{t('form.browser')}</Form.Label>
                    <select
                      className="custom-select"
                      name="browser"
                      defaultValue=""
                      ref={register({ required: t('error.required') as string })}>
                      <option value="">-- Browser --</option>
                      <option value="chrome">Chrome</option>
                      <option value="firefox">Firefox</option>
                      <option value="safari">Safari</option>
                      <option value="ie">Internet Explorer (Microsoft)</option>
                      <option value="other">{t('page.contact.other')}</option>
                    </select>
                    {errors.browser && (
                      <div className="invalid-feedback" style={{ display: 'block' }}>
                        {errors.browser.message}
                      </div>
                    )}
                  </Form.Group>
                </>
              )}
              {/* message */}
              <Form.Group>
                <Form.Label>{t('form.message')}</Form.Label>
                <textarea
                  name="description"
                  className="form-control"
                  rows={5}
                  placeholder={t('form.message_placeholder')}
                  ref={register({
                    required: t('error.required') as string,
                  })}
                />
                {errors.description && (
                  <div className="invalid-feedback" style={{ display: 'block' }}>
                    {errors.description.message}
                  </div>
                )}
              </Form.Group>
              <ReCAPTCHA
                ref={captchaRef}
                sitekey={process.env.GATSBY_RECAPTCHA_SITEKEY as string}
                onChange={onCaptchaSuccess}
                hl={i18n.language}
              />
              {errors.captcha && (
                <div className="invalid-feedback" style={{ display: 'block' }}>
                  {errors.captcha.message}
                </div>
              )}
              <ActionWrapper>
                <Button variant="primary" type="submit" disabled={submitting}>
                  {t('page.support.submit')}
                </Button>
              </ActionWrapper>
            </Form>
          </div>
        </div>
      </Container>
      <Toast
        onClose={() => setToast(false)}
        show={toast}
        delay={10000}
        autohide
        style={{
          position: 'absolute',
          top: 10,
          right: 10,
        }}>
        <Toast.Header>
          <strong className="mr-auto">{t('page.support.success')}</strong>
        </Toast.Header>
        <Toast.Body>{t('page.support.submitted')}</Toast.Body>
      </Toast>
    </Layout>
  );
};
export default index;
