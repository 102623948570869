import React, { FC } from 'react';
import { Link } from 'gatsby';
import { useTranslation, Trans } from 'gatsby-plugin-react-i18next';

const Header: FC = () => {
  const { t } = useTranslation();
  const link1 = <Link to="/support/watch-tutorials">link1</Link>;
  const link2 = <Link to="/support/help-guide">link2</Link>;
  const link3 = <Link to="/blog">link3</Link>;
  const link4 = <Link to="/support">link4</Link>;
  const link5 = <a href={process.env.GATSBY_LOGIN_URL}>link5</a>;
  const link6 = <a href={process.env.GATSBY_SUPPORT_URL}>link6</a>;

  return (
    <>
      <h1 className="text-center">{t('page.support.title')}</h1>
      <h3 className="text-center">{t('page.support.subtitle')}</h3>
      <div className="container flex-grow-1 d-flex flex-column align-items-center justify-content-center">
        <div className="w-50">
          <p className="text-center mt-3">
            <Trans i18nKey="page.support.description" components={[link1, link2, link3, link4, link5, link6]} />
          </p>
        </div>
      </div>
    </>
  );
};
export default Header;
